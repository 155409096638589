<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF aC">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
					<DatePicker @current-times-update="fetchChartData($event)" :disabled-compare-date="true" />
				</div>
				<a-tab-pane key="1" :tab="selectedSurvey.name || 'Overview'">
					<div class="dF fC aC mb-3">
						<a-alert type="info" show-icon style="width: max-content;">
							<template #message>
								To include or exclude any field in the survey report, simply update the "Include in Reporting" option in the <a :href="`https://forms.bildhive.${$tld}/edit/${$route.params.id}`" target="_blank" class="link">form's field configuration settings </a>.
							</template>
						</a-alert>
					</div>

					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [60, 10, 60, 10] : [17, 50, 17, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="loading">
								<BhLoading :show="loading" />
							</div>
							<div v-if="!error && surveyData.length" class="report-grid">
								<div v-for="(item, index) in surveyData" :key="index" class="html2pdf__page-break">
									<a-card :bordered="false" :loading="loading">
										<div data-html2canvas-ignore="true" class="dF aC jE">
											<span class="mr-2">Chart Type:</span>
											<a-select v-model="surveyDataChartType[item.label]" style="width: 80px"
												class="text-primary" :showArrow="true">
												<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
												<a-select-option value="bar" class="text-primary">
													Bar
												</a-select-option>
												<a-select-option value="line" class="text-primary">
													Line
												</a-select-option>
												<a-select-option value="pie" class="text-primary">
													Pie
												</a-select-option>
											</a-select>
										</div>
										<eChartLine :option="chartOptions[item.label]" :key="'title'" />
									</a-card>
								</div>
							</div>
							<div v-else-if="!surveyData.length && !loading" class="mt-10" style="text-align: center">
								<h4>No Record found in selected timeframe. Please update the timeframe and try again!</h4>
							</div>
							<div v-else-if="error" class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import BhLoading from 'bh-mod/components/common/Loading'
import { downloadCSV } from 'bh-mod'
import eChartLine from "@/components/charts/eChartLine";
import VueHtml2pdf from "vue-html2pdf";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import { mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
	components: {
		BhLoading,
		eChartLine,
		VueHtml2pdf,
		DatePicker,
		PDFOrientation,
	},
	data() {
		return {
			loading: true,
			error: false,
			surveyDataChartType: {},
			basicOptions: {
				title: {
					text: '',
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					top: 'bottom'
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: '',
						type: 'bar',
						data: [],
						colorBy: 'data',
						radius: '50%',
						lineStyle: {
							color: "#f7941e"
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			}
		}
	},
	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"records",
		]),

		surveyData() {
			return this.records.survey;
		},

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		selectedSurvey() {
			return this.$store.state.selectedSurvey || {}
		},

		chartType() {
			return this.$store.state.chartType
		},

		instance() {
			return this.$store.state.instance
		},

		chartOptions() {
			let surveyOptions = {};
			this.surveyData.map(item => {
				let options = JSON.parse(JSON.stringify(this.basicOptions));

				options.title.text = item.label;
				options.title.textStyle = {
					fontSize: 18,
					width: 400,
					overflow: "break",
					rich: {
						wrap: {
							width: 400,
							overflow: "break"
						}
					}
				}
				options.tooltip.trigger = this.surveyDataChartType[item.label] === 'pie' ? 'item' : 'axis'
				options.series[0].type = this.surveyDataChartType[item.label];

				if (this.surveyDataChartType[item.label] === 'pie') {
					options.series[0] = {
						...options.series[0],
						data: item.stats.map(s => { return { value: s.count, name: s.value } }),
					}
				} else {
					options.xAxis.data = item.stats.map(s => s.value);
					options.series[0] = {
						...options.series[0],
						data: item.stats.map(s => s.count)
					}
				}

				surveyOptions[item.label] = options;
			})

			return surveyOptions;
		},

		filename() {
			return `${this.selectedSurvey.name || 'Survey Report'} (${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},
	},
	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),
		decodeHTMLEntities(str) {
			const parser = new DOMParser();
			const decodedString = parser.parseFromString(str, "text/html").body.textContent;
			return decodedString;
		},
		async fetchChartData({ startDate = this.currentStartDate, endDate = this.currentEndDate }, dateType = "currentData") {
			this.loading = true;
			this.error = false;
			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}
			try {
				this.surveyDataChartType = {};
				const { data } = await this.$api.get(`/forms/:instance/${this.$route.params.id}/reports?start=${startDate}&end=${endDate}`);
				data.forEach((item) => {
					item.stats = item.stats.map(x => {
						x.value = this.decodeHTMLEntities(x.value);
						return x;
					})
					this.$set(this.surveyDataChartType, item.label, 'bar')
				})
				this.setRecords({
					records: data,
					type: "survey",
				});
			} catch (err) {
				this.error = true;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching survey details. Please try again.`))
				}
			}
			this.loading = false;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		async getCSV() {
			this.$store.commit("LOAD", true);
			try {
				await downloadCSV(`/forms/:instance/${this.selectedSurvey.id}/csv`, `${this.selectedSurvey.name}_${Date.now()}`, "reports")
				this.$message.success("Survey data exported successfully");
			} catch (error) {
				this.$toastError(error, 'An error occurred while downloading survey csv data. Please try again.')
			} finally {
				this.$store.commit("LOAD", false);
			}
		},

		async fetchSurveyDetails() {
			try {
				const { data } = await this.$api.get(`forms/:instance/${this.$route.params.id}`)
				this.$store.commit('SET_SURVEY', data)
			} catch (err) {
				console.error(err);
			}
		}
	},
	created() {
		if ((!this.selectedSurvey || !this.selectedSurvey.name) && this.$route.params.id) {
			this.fetchSurveyDetails()
		}
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
